import allRoutes, { RoutePathDefinition } from '@battlefly/router/routes';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

export default function App() {
  const [mounted, setMounted] = useState(false);

  // When mounted on client, now we can show the UI
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  return (
    <Routes>
      <>
        {allRoutes.map(({ path, element }: RoutePathDefinition) => {
          if (element) {
            return <Route key={path} path={path} element={element()} />;
          }
          return null;
        })}
        <Route
          path="*"
          element={
            <main style={{ padding: '1rem' }}>
              <p>{`There's nothing here yet!`}</p>
            </main>
          }
        />
      </>
    </Routes>
  );
}
